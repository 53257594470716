import Fetch from './Fetch'

class Product {
  fetch = null

  static {
    this.fetch = new Fetch()
  }

  static get ({ page, paginate = true, search = '' }) {
    this.fetch.endpoint = 'products'
    this.fetch.method = 'GET'
    this.fetch.queries.set('page', page)
    this.fetch.queries.set('search', search)
    paginate === false && this.fetch.queries.set('paginate', paginate)
    return this.fetch.performRequest()
  }

  static save (data) {
    this.fetch.endpoint = 'products'
    this.fetch.method = 'POST'
    this.fetch.body = data
    return this.fetch.performRequest()
  }

  static delete (id) {
    this.fetch.endpoint = `products/${id}`
    this.fetch.method = 'DELETE'
    return this.fetch.performRequest()
  }

  static show (id) {
    this.fetch.endpoint = `products/${id}`
    this.fetch.method = 'GET'
    return this.fetch.performRequest()
  }

  static update (id, data) {
    this.fetch.endpoint = `products/${id}`
    this.fetch.method = 'PUT'
    this.fetch.body = data
    return this.fetch.performRequest()
  }

  static assignForm ({ product, form }) {
    this.fetch.endpoint = `products/${product}/assign-form`
    this.fetch.method = 'POST'
    this.fetch.body = { form }
    return this.fetch.performRequest()
  }

  static unassignForm ({ product, form }) {
    this.fetch.endpoint = `products/${product}/unassign-form/${form}`
    this.fetch.method = 'DELETE'
    return this.fetch.performRequest()
  }

  static exchange ({ product }) {
    this.fetch.endpoint = `products/${product}/exchange`
    this.fetch.method = 'POST'
    this.fetch.body = {}
    return this.fetch.performRequest()
  }

  static getExchanges(user, product) {
    this.fetch.endpoint = 'products/total-exchanges'
    if (user) this.fetch.queries.set('user', user)
    if (product) this.fetch.queries.set('product', product)
    this.fetch.method = 'GET'
    return this.fetch.performRequest()
  }

  static showExchange(id) {
    this.fetch.endpoint = `products/total-exchanges/${id}`
    this.fetch.method = 'GET'
    return this.fetch.performRequest()
  }
}

export default Product