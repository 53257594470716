import { Link } from 'react-router-dom';
import CheckRole from '../../../components/CheckRole';

export function UserExchangesActionColumn({rowData}) {
  return (
    <>
      <CheckRole has='view_user_exchanges'>
        <Link
          to={`/user-exchanges/${rowData._id}`}
          className='bg-black hover:bg-gray-700 px-2 py-1 rounded-md ease-in-out duration-200'
        >
          <img className='w-[25px]' src='/img/view.svg' alt='' />
        </Link>
      </CheckRole>
    </>
  )
}