import React from 'react'
import { PageHeading } from '../../components/Headings'
import Card from '../../components/Card'
import { Link } from 'react-router-dom'
import CheckRole from '../../components/CheckRole'
import useUsers from '../../hooks/useUsers'
import useLocale from '../../hooks/useLocale'
import Button from '../../components/Button'
import DataGridView from '../../components/DataGridView'
import {UsersActionColumn} from './components/ActionColumn'
import { StatusColumn } from './components/StatusColumn'

function ManageUsers() {
  const {users, handleDelete, exportXlsx, getUsers, platforms} = useUsers()
	const { l } = useLocale()

  const columns = [
    {
      header: l('name'),
      accessor: 'name',
      filtrable: true,
    },
    {
      header: l('email'),
      accessor: 'email',
      filtrable: true
    },
    {
      header: l('points'),
      component: ({rowData}) => <span>{rowData.points?.toLocaleString('es-ES', {minimumFractionDigits: 2, maximumFractionDigits: 2}) || 0} pts</span>,
      cellClass: 'text-end'
    },
    {
      header: l('platform'),
      accessor: 'platform?.name',
      cellClass: 'text-center',
      filtrable: true,
      filterType: 'select',
      options: [{value: '', label: l('all_platforms')}, ...platforms]
    },
    {
      header: l('account_state'),
      component: (props) => <StatusColumn {...props} refresh={getUsers} />,
      cellClass: 'text-center'
    },
    {
      header: l('actions'),
      component: ({rowData, page, limit, filters}) => <UsersActionColumn handleDelete={(id) => handleDelete(id, page, limit, filters)} rowData={rowData} />,
      cellClass: 'flex gap-2 justify-center'
    }
  ]

	return (
		<>
			<PageHeading image='/img/users.svg' title={l('manage_users')}>
				<CheckRole has='add_users'>
					<Link
						className='bg-gray-500 px-2 py-1 rounded-md hover:bg-gray-400 ease-in-out duration-200'
						to='/users/create'
					>
						{l('add')}
					</Link>
				</CheckRole>
        <CheckRole has={'export_users'}>
          <Button onClick={exportXlsx} type='warning'>{l('export')}</Button>
        </CheckRole>
			</PageHeading>
			<Card>
        <DataGridView columns={columns} data={users.docs} getData={getUsers} totalPages={users.totalPages} />
			</Card>
		</>
	)
}

export default ManageUsers
