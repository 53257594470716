import {PageHeading} from '../../components/Headings'
import Card from '../../components/Card'
import useLocale from '../../hooks/useLocale'
import React from 'react'
import Product from '../../models/Product'
import DataGridView from '../../components/DataGridView'
import { UserExchangesActionColumn } from './columns/ActionColumn'

export function UserExchangesPage() {
  const {l} = useLocale()
  const [exchanges, setExchanges] = React.useState([])

  const getData = React.useCallback(async ({filters = {}} = {}) => {
    try {
      console.log('filters', filters);
      const exchanges = await Product.getExchanges(filters['user.name'], filters['product.name'])
      setExchanges(exchanges);
    }catch (err) {
      alert('Error', err.message)
    }
  }, [])

  const columns = [
    {
      header: l('user'),
      accessor: 'user.name',
      filtrable: true,
    },
    {
      header: l('product'),
      accessor: 'product.name',
      filtrable: true,
    },
    {
      header: l('points'),
      accessor: 'price'
    },
    {
      header: l('actions'),
      component: ({rowData, page, limit, filters}) => <UserExchangesActionColumn rowData={rowData} />,
      cellClass: 'flex gap-2 justify-center'
    }
  ];

  return (
    <>
      <PageHeading title={l('user_exchanges')} image={'/img/exchange.svg'}></PageHeading>
      <Card>
        <DataGridView showPagination={false} getData={getData} data={exchanges} columns={columns} />
      </Card>
    </>
  )
}