import React from 'react';
import useLocale from '../../hooks/useLocale';
import Button from '../Button';
import TextFilter from './TextFilter';
import SelectFilter from './SelectFilter';
import {useDebounce} from '../../hooks/useDebounce'

const filterTypes = {
  'text': TextFilter,
  'select': SelectFilter
}

const DataGridView = ({columns = [], data = [], getData, totalPages = 1, name = null, showPagination = true}) => {
  const {l} = useLocale()
  const fieldsRef = React.useRef({})
  const [tableState, setTableState] = useDebounce({
    page: 1,
    limit: 10,
    filters: {}
  }, 500)
  const hasFilterableColumn = columns.some(column => column.filtrable);

  React.useEffect(() => {
    if (!getData) return;
    getData({limit: tableState.limit, page: tableState.page, filters: tableState.filters})
  }, [tableState, getData])

  function handleFilterChange (e, accessor) {
    setTableState(old => ({
      page: 1,
      limit: old.limit,
      filters: {
        ...old.filters,
        [accessor]: e.target.value
      }
    }))
  };

  React.useEffect(() => {
    if (name === null) return;
    const config = JSON.parse(localStorage.getItem(`table_${name}`))
    if (!config) return;
    setTableState(config)
  }, [name, setTableState])

  React.useEffect(() => {
    if (name === null) return;
    localStorage.setItem(`table_${name}`, JSON.stringify(tableState))
  }, [tableState, name])

  const resetFilters = () => {
    setTableState(old => ({
      ...old,
      filters: {}
    }));
    for (const field in fieldsRef.current) {
      const fieldRef = fieldsRef.current[field]
      fieldRef.value = ''
    }
  };

  return (
    <div>
      {hasFilterableColumn ? (
        <div className="flex justify-end">
          <Button onClick={resetFilters}>{l('clean_filters')}</Button>
        </div>
      ) : null}
      <table className="min-w-full border-collapse">
        <thead> 
          <tr>
            {columns.map((column, idx) => (
              <th key={idx} className="py-2 px-4 border-b-2 border-gray-300 dark:border-gray-600">{column.header}</th>
            ))}
          </tr>
          {hasFilterableColumn ? (
            <tr>
              {columns.map((column, idx) => (
                <th key={idx} className="px-4 py-2">
                  {column.filtrable ? (
                    React.createElement(filterTypes[column.filterType || 'text'], {
                      reference: (el) => {
                        fieldsRef.current[column.accessor] = el;
                      },
                      onChange: (e) => handleFilterChange(e, column.accessor),
                      options: column.options || []
                    })
                  ) : null}
                </th>
              ))}
            </tr>
          ) : null}
        </thead>
        <tbody>
          {data.map((row, idx) => (
            <tr key={idx} className="hover:bg-gray-500 border-b border-gray-600 odd:bg-gray-600">
              {columns.map((column, idx) => (
                <td key={idx} className={`py-2 px-4 ${column.cellClass}`}>
                  {column.component ? React.createElement(column.component, {columnData: column, rowData: row, page: tableState.page, limit: tableState.limit, filters: tableState.filters}) : eval(`row.${column.accessor}`) || '-'}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>

      {showPagination && (
        <div className='flex flex-row-reverse gap-3'>
          <div className='flex flex-row gap-1'>
            <span>{l('page')} {tableState.page} {l('of')} {totalPages}</span>
            <button disabled={tableState.page <= 1} onClick={() => setTableState((old) => ({...old, page: old.page - 1}))} className='disabled:opacity-50'>
              <img src="/img/arrow2.svg" className='w-[20px] rotate-180' alt="" />
            </button>
            <button disabled={tableState.page >= totalPages} onClick={() => setTableState(old => ({...old, page: old.page + 1}))} className='disabled:opacity-50'>
              <img src="/img/arrow2.svg" className='w-[20px]' alt="" />
            </button>
          </div>
          <div>|</div>
          <div>
            <span className='mr-5'>Rows per page:</span>
            <select className='bg-transparent text-white' value={tableState.limit} onChange={e => setTableState(old => ({...old, limit: Number(e.target.value)}))}>
              {[1, 10, 50, 100, 200].map(size => (
                <option key={size} value={size}>{size}</option>
              ))}
            </select>
          </div>
        </div>
      )}
    </div>
  );
};

export default DataGridView;
