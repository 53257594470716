import React from 'react';
import Card from '../../components/Card';
import { PageHeading } from '../../components/Headings';
import useLocale from '../../hooks/useLocale';
import Product from '../../models/Product';
import { useParams } from 'react-router-dom';

export function ShowUserExchange() {
  const {l} = useLocale()
  const {id} = useParams()
  const [exchange, setExchange] = React.useState({})
  
  const getData = React.useCallback(async () => {
    try {
      const exchange = await Product.showExchange(id)
      setExchange(exchange)
    } catch(err) {
      alert('error', err.message)
    }
  }, [id])

  React.useEffect(() => {
    getData()
  }, [getData])

  return (
    <>
      <PageHeading title={l('user_exchange')} image={'/img/exchange.svg'}></PageHeading>
      <Card>
        {/* <pre>{JSON.stringify(exchange, null, 2)}</pre> */}

        <div className='grid grid-cols-3'>
          <div className='flex flex-col gap-2'>
            <span className="font-bold text-xl">{l('user_info')}</span>
            <span>{l('name')}: {exchange.user?.name}</span>
            <span>{l('email')}: {exchange.user?.email}</span>
          </div>

          <div className='flex flex-col gap-2'>
            <span className="font-bold text-xl">{l('product_info')}</span>
            <span>{l('name')}: {exchange.product?.name}</span>
            <span>{l('exchange_price')}: {exchange.price} pts</span>
          </div>

          <div className='flex flex-col gap-2'>
            <span className="font-bold text-xl">{l('shipping_info')}</span>
            {Object.entries(exchange.shippingInfo || {}).map(([key, val]) => <p><strong>{key}</strong>: {val}</p>)}
          </div>
        </div>
      </Card>
    </>
  )
}